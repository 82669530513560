import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import Search from "../Search";
import useCustomEventListener from "@/hooks/useCustomEventListener";
import {overlay} from "@/store/Overlay";

const SearchDropDown = ({suggestions}) => {
  const [open, setOpen] = useState(false);

  const toggleHandler = () => {
    setOpen(!open);
  };

  useCustomEventListener("search:dropdown:toggle", toggleHandler);

  useEffect(() => {
    const handleClickOutside = event => {
      const searchDropdown = document.querySelector(".search-dropdown");
      if (searchDropdown && !searchDropdown.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      overlay.open();
    } else {
      overlay.close();
    }
  }, [open]);

  if (!open) return null;

  return (
    <div className="search-dropdown absolute top-0 w-full rounded-b-xl bg-gray shadow-bottom-sm">
      <Search suggestions={suggestions} isInPageSearch={true} />
    </div>
  );
};

SearchDropDown.propTypes = {
  suggestions: PropTypes.array,
};

export default SearchDropDown;
